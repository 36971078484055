// initial state
const state = () => ({
    user: '',
})





// getters
const getters = {
    user: state => state.user
}





// actions
const actions = {
    setUser({ commit }, user) {
        commit('setUser', user);
    }
}





// mutations
const mutations = {
    setUser(state, user) {
        state.user = user;
    }
}





export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}