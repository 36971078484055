import { createStore } from 'vuex'



// General App Store Modules
import Login from '../views/Login/Login'
import Toast from '../components/Toast/Toast'
import Modal from '../components/Modal/Modal'
import PageLoader from '../components/PageLoader/PageLoader'
import User from './modules/User'
import Menu from '../views/Menus/Menu.js'


// Categories
import Categories from '../views/Categories/Categories'



export default createStore({
  modules: {
    Login,
    Toast,
    Modal,
    PageLoader,
    User,
    Menu,

    // Categories
    Categories
  }
})