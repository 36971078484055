<template>
    <div class="user-account-wrap">
        User Account
        <button @click="$store.dispatch('Login/logout')">Log out</button>
    </div>
</template>




<script>
export default {

    data() {
        return {

        }
    },




}
</script>




<style>
    
</style>