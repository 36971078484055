/* FontAwesome Icons */
import { library } from '@fortawesome/fontawesome-svg-core'

import { 
  faBan,
  faBarcode,
  faCheckCircle,
  faCircleChevronLeft,
  faCircleChevronRight,
  faCirclePlus,
  faCircleUser,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFloppyDisk,
  faInfoCircle,
  faList,
  faMagnifyingGlass,
  faPencil,
  faRectangleList,
  faSpinner,
  faSyncAlt,
  faTags,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'



import { 
  faCheckCircle as farCheckCircle,
  faTimesCircle as farTimesCircle
} from '@fortawesome/free-regular-svg-icons'



library.add(
  faEye,
  faEyeSlash,
  faInfoCircle,
  faCheckCircle,
  faExclamationTriangle,
  faExclamationCircle,
  farCheckCircle,
  faCircleUser,
  faPencil,
  faFloppyDisk,
  faBan,
  faCirclePlus,
  faSpinner,
  faTrash,
  faMagnifyingGlass,
  faTags,
  faBarcode,
  faSyncAlt,
  faList,
  faRectangleList,
  faCircleChevronLeft,
  faCircleChevronRight
)