<template>
	
	<PageLoader />
	<Toast />
	<Modal />
	<Menu />
	
	<!-- <div style="margin-top: 30px">
		<p style="color: var(--AppRed);">App Red</p>
		<p style="color: var(--AppBlack);">App Black</p>
		<p style="color: var(--AppWhite);">App White</p>
		<p style="color: var(--TextWhite);">Text White</p>
		<p style="color: var(--TextBlack);">Text Black</p>
	</div> -->

	

	<router-view />


</template>




<script>

import Menu from './views/Menus/Menu.vue'
import Toast from './components/Toast/Toast.vue'
import Modal from './components/Modal/Modal.vue'
import PageLoader from './components/PageLoader/PageLoader.vue'
import { axios } from './axios/axios'
import { mapGetters } from 'vuex'

export default {

	components: { Menu, Toast, Modal, PageLoader },
	
	data() {
		return {
			catName: ''
		}
	},


	watch: {
		modal: {
            handler: function() {
                if(this.modal.confirmAction === true && this.modal.actionFrom.indexOf('refresh_app_sw') !== -1)
                    this.refreshServiceWorker();                
            },
            deep: true
        },
	},



	created() {
		navigator.serviceWorker.getRegistration().then(reg => { this.listenForWaitingServiceWorker(reg, this.promptUserToRefresh) }); 
		navigator.serviceWorker.addEventListener('controllerchange', function() { window.location.reload() });
	},



	computed: {
		...mapGetters({
			modal: ['Modal/modal']
		})
	},



	mounted() {
		// console.log('App mounted.');
		// var modal = 
		// 	{
		// 		active: true, // true to show modal
		// 		type: 'info', // ['info', 'warning', 'error', 'okay']
		// 		icon: [], // Leave blank for no icon
		// 		heading: 'Test Modal',
		// 		body: 'testing',

		// 		// Optional add on for when user needs to confirm or deny an action
		// 		// confirmAction: 'init',
		// 		// actionFrom: 'refresh_app_sw',
		// 		// resolveText: 'Okay',
		// 		// rejectText: 'Cancel'
				
		// 	}
		// 	this.$store.dispatch('Modal/modal', modal);
	},	


	methods: {
		runAPITest: function() {
			axios.post('categories/create_user_category.php', { userId: 1, catName: this.catName })
			.then(resp => {
				console.log(resp);
				this.catName = '';
			})
			
		},






		listenForWaitingServiceWorker: function (reg, callback) {
			console.log('Checking for new sw...');
			function awaitStateChange() {
				reg.installing.addEventListener('statechange', function() {
					if (this.state === 'installed') callback(reg)
				});
			}

			if (!reg) return;
			if (reg.waiting) return callback(reg);
			if (reg.installing) {
				awaitStateChange();
			}
			reg.addEventListener('updatefound', () => {
				awaitStateChange();
			});

		},





		promptUserToRefresh: function(reg) {

			var modal = 
			{
				active: true, // true to show modal
				type: 'info', // ['info', 'warning', 'error', 'okay']
				icon: [], // Leave blank for no icon
				heading: 'App Update Available',
				body: 'There is updated software available for your App. Please update as soon as possible.',

				// Optional add on for when user needs to confirm or deny an action
				confirmAction: 'init',
				actionFrom: 'refresh_app_sw',
				resolveText: 'Update',
				rejectText: 'Cancel'
				
			}
			this.$store.dispatch('Modal/modal', modal);

		},





		refreshServiceWorker: function() {
			console.log('Refreshing the service worker');
			navigator.serviceWorker.getRegistration().then(reg => {
				reg.waiting.postMessage({type: 'skipWaiting'});
			})
		},
	}


}

</script>




<style>


/* App Colors */

:root {
	--AppRed: rgb(181, 21, 46);
	--AppRedDark: rgb(89, 17, 27);
	--AppBlack: rgb(9, 9, 9);
	--AppWhite: rgb(241, 241, 241);
	--TextWhite: rgb(235, 235, 235);
	--TextBlack: rgb(15, 15, 15);
	--TextGrey: rgb(115,115,115);
	--OkGreen: rgb(42, 130, 33);
	--LightGreen: rgb(51, 209, 33);
	--WarningRed: rgb(198, 25, 25);
	--BackgroundWhite: rgba(240,240,240,0.95);
}





/* General Global App CSS */

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Raleway', sans-serif;
	scroll-behavior: smooth;
	outline: none;
	-webkit-tap-highlight-color: transparent;
	/* -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale; */
}

body {
	transition: all 150ms ease-out;
	background: var(--Grey);
	color: var(--TextBlack);
	margin-top: 60px; /* Menu spacing */
}

a {
	text-decoration: none;
	color: var(--MidBlue);
}

button a {
	color: inherit;
}

input {
	height: 35px;
	position: relative;
	z-index: 10;
	padding-left: 8px;
}

input::placeholder {
    color: rgba(15,15,15,0.5);
}

input:disabled::placeholder {
	color: rgba(15,15,15,0.2);
}

button {
	height: 35px;
	padding: 8px 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	background: var(--AppWhite);
	color: var(--TextBlack);
	border: none;
	border-radius: 3px;
	font-weight: 700;
	z-index: 10;
	position: relative;
}




/* Specific Global App CSS */

.app-red {
	color: var(--AppRed);
}

.bold {
	font-weight: 700;
}

button:disabled {
	color: rgb(170,170,170);
}

.btn-icon {
	margin-right: 5px;
}



.btn-icon.disabled,
button:disabled .btn-icon, 
.icon.disabled {
	color: rgb(150,150,150);
}


.data-grid {
	display: grid;
}


.data-grid-item-wrap {
	min-height: 40px;
	height: 100%;
	width: 100%;
	border-bottom: 1px solid rgba(40,40,40,0.4);
	display: flex;
    align-items: flex-end;
    padding-bottom: 2px;
	/* border-radius: 3px; */
}


.data-grid-item-wrap.center {
	display: flex;
	align-items: center;
	justify-content: center;
}


.data-grid-item-wrap.center-v {
	display: flex;
	align-items: center;
}









.custom-scroller::-webkit-scrollbar {
	width: 7px;
}
.custom-scroller::-webkit-scrollbar-track {
	background: rgba(97, 97, 97, 0.95);
}
.custom-scroller::-webkit-scrollbar-thumb {
	background: var(--AppRed);
}
.custom-scroller::-webkit-scrollbar-thumb:hover {
	background: #555;
}



.no-scroll-bar::-webkit-scrollbar {
	width: 0px;
}
.no-scroll-bar::-webkit-scrollbar-track {
	background: transparent;
}
.no-scroll-bar::-webkit-scrollbar-thumb {
	background: transparent;
}
.no-scroll-bar::-webkit-scrollbar-thumb:hover {
	background: transparent;
}








/* (Google) Material Icons - Outlined | Fill 1 | Weight 700 */
@font-face {
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  src: url('./fonts/MaterialSymbolsOutlined.woff2') format('woff2');
}



.material-symbols-outlined {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
}



.material-symbols-outlined {
  font-variation-settings:
  'FILL' 1,
  'wght' 700,
  'GRAD' 0,
  'opsz' 48
}


</style>
