import { axios } from '../../axios/axios'

// initial state
const state = () => ({
    loadingCategories: false,
    loadingTags: false,
    updatingTags: false,
    userCategories: [],
    selectedCategoryId: '',
    dashboardCatId: 0,
})





// getters
const getters = {
    loadingCategories: state => state.loadingCategories,
    loadingTags: state => state.loadingTags,
    updatingTags: state => state.updatingTags,
    userCategories: state => state.userCategories,
    selectedCategoryId: state => state.selectedCategoryId,
    selectedCategoryTags: state => state.userCategories.find(cat => cat.id == selectedCategoryId).tags,
    dashboardCatId: state => state.dashboardCatId
}





// actions
const actions = {
    loadingCategories({ commit }, toggle) {
        commit('loadingCategories', toggle);
    },

    loadingTags({ commit }, toggle) {
        // console.log("Loading tags? ", toggle);
        commit('loadingTags', toggle);
    },


    updatingTags({ commit }, toggle) {
        commit('updatingTags', toggle);
    },


    setUserCategories({ commit }, userCategories) {
        commit('setUserCategories', userCategories);
    },


    selectedCategoryId({ commit }, selectedCategoryId) {
        commit('selectedCategoryId', selectedCategoryId);
    },


    dashboardCatId({ commit }, dashboardCatId) {
        commit('dashboardCatId', dashboardCatId);
    },


    async getUserCategories({ commit, dispatch }, userId) {

        // console.log('user id: ', userId);

        dispatch('loadingCategories', true);

        await axios.post('categories/get_user_categories.php', { userId })
        .then(resp => {
            // console.log(resp);
            if(resp.data.status)
            {
                var userCategories = resp.data.data;
                userCategories.push(
                    {
                        id: 0,
                        category_name: 'Unassigned Tags',
                        user_id: userId
                    }
                )
                userCategories.map(cat => {
                    cat['tagsLoaded'] = false;
                    cat['tags'] = [];
                    cat['editing'] = false;
                    cat['deleted'] = false;
                    cat['editName'] = JSON.parse(JSON.stringify(cat.category_name));

                });
                // console.log('User Categories retrieved: ', userCategories);

                commit('setUserCategories', userCategories);
            }
            else
            {
                var toast = {
                    shown: false,
                    type: 'error', // ['info', 'warning', 'error', 'okay']
                    heading: 'Server Error', // (Optional)
                    body: 'Could not retrieve categories',
                    time: 5000, // in milliseconds
                    icon: '' // leave blank for default toast type icon
                }
                dispatch('Toast/toast', toast, { root: true });
            }
            
            dispatch('loadingCategories', false);
        })
        .catch(err => {
            console.error('Connection Error: ', err);
            console.error('Connection Error Response: ', err.response);
            var toast = {
                shown: false,
                type: 'error', // ['info', 'warning', 'error', 'okay']
                heading: 'Server Error', // (Optional)
                body: 'Please try again later', 
                time: 500, // in milliseconds
                icon: '' // leave blank for default toast type icon
            }
            dispatch('Toast/toast', toast, { root: true });
            dispatch('loadingCategories', false);
        })
    },




    updateUserCategories({ state }) {
        state.userCategories = state.userCategories.filter(cat => !cat.deleted);
    },






    async loadCategoryTags({ commit, dispatch, state }, { userId, refresh, categoryId }) {

        console.log('State categories: ', state.userCategories, 'sent categoryId: ', categoryId);

        var searchCategoryId = categoryId ? categoryId : state.selectedCategoryId;

        var selectedCategory = state.userCategories.find(cat => Number(cat.id) == Number(searchCategoryId));
        console.log('Loading Categories, have a selected category? ', selectedCategory);
        if(selectedCategory && selectedCategory.tagsLoaded && !refresh) { return }

        dispatch('loadingTags', true);

            var body = { userId, catId: selectedCategory.id }

            console.log('Getting tags using body: ', body);

            await axios.post('tags/get_user_tags_by_category.php', body)
            .then(resp => {
                // console.log(resp);
                if(resp.data.status)
                {   
                    dispatch('setCategoryTags', { tags: resp.data.data, categoryId: categoryId ? categoryId : selectedCategory.id });
                }
                else
                {
                    var toast = {
                        shown: false,
                        type: 'error', // ['info', 'warning', 'error', 'okay']
                        heading: 'Server Error', // (Optional)
                        body: 'Could not load tags', 
                        time: 5000, // in milliseconds
                        icon: '' // leave blank for default toast type icon
                    }

                    dispatch('Toast/toast', toast, { root: true });
                    dispatch('loadingTags', false);
                }
            })
            .catch(err => {
                console.error('Connection Error: ', err);
                console.error('Connection Error Response: ', err.response);
                var toast = {
                    shown: false,
                    type: 'error', // ['info', 'warning', 'error', 'okay']
                    heading: 'Server Error', // (Optional)
                    body: 'Please try again later', 
                    time: 500, // in milliseconds
                    icon: '' // leave blank for default toast type icon
                }
                this.$store.dispatch('Toast/toast', toast, { root: true });
                dispatch('loadingTags', false);
            })

    },



    setCategoryTags({ commit },{ tags, categoryId }) {
        commit('setCategoryTags', { tags, categoryId });
    }
}





// mutations
const mutations = {
    loadingCategories(state, toggle) {
        state.loadingCategories = toggle;
    },


    loadingTags(state, toggle) {
        state.loadingTags = toggle;
        // console.log('Switched loading tags: ', state.loadingTags);
    },


    updatingTags(state, toggle) {
        state.updatingTags = toggle;
    },


    setUserCategories(state, categories) {

        // console.log('Setting User categories');

        categories.sort((a,b) => a.category_name.localeCompare(b.category_name));

        var unassignedTags = categories.find(cat => cat.category_name == 'Unassigned Tags');
        var unassignedTagsIndex = categories.findIndex(cat => cat.category_name == 'Unassigned Tags');
        categories = categories.filter((cat, index) => index != unassignedTagsIndex);
        categories.unshift(unassignedTags);

        state.userCategories = categories;
    },


    selectedCategoryId(state, selectedCategoryId) {
        state.selectedCategoryId = selectedCategoryId;
    },


    dashboardCatId(state, dashboardCatId) {
        state.dashboardCatId = dashboardCatId;
    },



    setCategoryTags(state, { tags, categoryId }) {
        
        if(tags && tags.length >= 1)
        {
            tags.map(tag => {
                tag['selectedCategoryId'] = JSON.parse(JSON.stringify(tag.category_id));
            });
        }

        state.userCategories.map(cat => {
            if(cat.id == categoryId)
            {
                cat.tags = [];
                cat.tags = tags;
                cat.tagsLoaded = true;
            }

            cat.tags.sort((a, b) => a.tag_detail_id - b.tag_detail_id)
        })

        // console.log('Tags updated in state: ', state.userCategories);
        state.loadingTags = false;
    }
}





export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}