<template>
    <div class="page-loader-wrap" :class="{ 'full-screen' : heading == 'Logging in...' }" v-if="loading">
        <div class="page-loader-content">
            <font-awesome-icon class="loading-spinner page-loader" :icon="['fa', 'spinner']" spin size="lg" />
            <h2>{{ heading }}</h2>
            <p>{{ message }}</p>
        </div>
    </div>
</template>



<script>
import { mapGetters } from 'vuex';

export default {

    data() {
        return {

        }
    },



    computed: {
        ...mapGetters({
            loading: ['PageLoader/loading'],
            heading: ['PageLoader/heading'],
            message: ['PageLoader/message']
        })
    },



    watch: {
        
    },



    mounted() {
        
    }

}
</script>



<style>

.page-loader-wrap {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: calc(100vh - 60px);
    background: rgba(240,240,240,0.85);
    z-index: 2000;
    color: var(--TextBlack);
}


.page-loader-wrap.full-screen {
    height: 100vh;
}



.page-loader-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: -9%;
}



.loading-spinner.page-loader {
    margin-bottom: 8px;
    color: var(--AppRed);
    font-size: 30px;
}
    
</style>