import axios from 'axios'


if(window.location.hostname === 'localhost')
    axios.defaults.baseURL = 'http://localhost:8008/cow_count/api';
else
    axios.defaults.baseURL = 'https://projects.bravado.co.za/api';



export {
    axios
}