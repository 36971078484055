<template>
    <div>
        <h2>Page not found.</h2>
        <p>The page you are trying to access does not exist on our application.</p>
        <p>Please use the menu to navigate to your desired page.</p>
    </div>
</template>



<script>
export default {
    
}
</script>



<style>
    
</style>