<template>
    <div class="menu-wrap" v-if="isAuth">
        <div class="menu-title-wrap">
            <!-- <img class="menu-logo" @click="$router.push('dashboard')" :src="require('@/assets/logo_red.png')" alt="app_menu_logo"> -->
            <font-awesome-icon v-if="(titleIcon && titleIcon.length === 2 && typeof titleIcon === 'object')" class="page-heading-icon" :icon="$route.meta.icon" size="lg" />
            <span v-else-if="(typeof titleIcon === 'string')" class="material-symbols-outlined page-heading-icon material">{{ $route.meta.icon }}</span>
            <h2>{{ title }}</h2>
        </div>

        <div class="menu-links-wrap">
            <font-awesome-icon @click="$router.push('/user_account')" class="user-account-link" :icon="['fa', 'circle-user']" size="lg" />
            <div class="three-dots-menu-wrap" @click="showDropDown = !showDropDown">
                <span class="three-dots-menu"></span>
            </div>
        </div>

        <div id="MenuDropDown" class="menu-drop-down-wrap" :class="{ 'active' : showDropDown }">

            <p @click="$router.push('/dashboard'), showDropDown = false"><font-awesome-icon class="menu-icon" :icon="['fa', 'tags']"  size="lg" /> Dashboard</p>
            <p @click="$router.push('/categories_dashboard'), showDropDown = false"><font-awesome-icon class="menu-icon" :icon="['fa', 'list']"  size="lg" /> Categories</p>
            <p @click="$router.push('/tag_categories'), showDropDown = false"><font-awesome-icon class="menu-icon" :icon="['fa', 'rectangle-list']"  size="lg" /> Tag Categories</p>

        </div>
    </div>
</template>




<script>
import { mapGetters } from 'vuex';

export default {

    data() {
        return {
            showDropDown: false
        }
    },



    computed: {
        ...mapGetters({
            isAuth: ['Login/isAuth'],
            titleIcon: ['Menu/titleIcon'],
            title: ['Menu/title']
        })
    },




    watch: {
        showDropDown: function() {
            if(this.showDropDown === true)
                setTimeout(() => {
                    document.addEventListener('click', this.hideMenu)
                }, 150)
            else
                document.removeEventListener('click', this.hideMenu)
        },
    },




    methods: {

        hideMenu: function(e) {
            var menuDropDown = document.getElementById('MenuDropDown');
            if(menuDropDown && !menuDropDown.contains(e.target))
                this.showDropDown = false;
        }

    }

}
</script>




<style>

.menu-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: rgba(245,245,245,0.95);
    color: var(--TextBlack);

    display: grid;
    grid-template-columns: 3fr 1fr;
    z-index: 2001;
}


.menu-title-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.menu-title-wrap h2 {
    padding-bottom: 1px;
}



.page-heading-icon {
    margin-right: 10px;
    color: var(--AppRed);
    font-size: 20px;
}




.menu-links-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}






.user-account-link {
    margin-right: 10px;
    font-size: 30px;
    color: var(--AppBlack);
}



.three-dots-menu-wrap {
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.three-dots-menu {
    position: relative;
    width: 8px;
    height: 8px;
    background: var(--AppBlack);
    border-radius: 50%;
    margin-right: 25px;
}

.three-dots-menu::after {
    content: '';
    position: absolute;
    bottom: 12px;
    left: 0;
    width: 8px;
    height: 8px;
    background: var(--AppBlack);
    border-radius: 50%;
}

.three-dots-menu::before {
    content: '';
    position: absolute;
    top: 12px;
    left: 0;
    width: 8px;
    height: 8px;
    background: var(--AppBlack);
    border-radius: 50%;
}









.menu-drop-down-wrap {
    position: fixed;
    top: 60px;
    right: -200px;
    width: 180px;
    transition: right 250ms ease;
    background: rgba(220,220,220,0.98);
}

.menu-drop-down-wrap.active {
    right: 0;
    transition: right 250ms ease;
}



.menu-drop-down-wrap p {
    padding: 12px 8px;
    cursor: pointer;
    border-bottom: 1px solid rgb(255,255,255);
    display: flex;
    align-items: center;
}


.menu-drop-down-wrap p:last-child {
    border-bottom: none;
}


.menu-drop-down-wrap .menu-icon {
    margin-right: 5px;
    font-size: 14px;
    color: var(--AppRed);
}


.menu-dashboard-icon {
    width: 16px;
    margin-right: 5px;
}
    
</style>