<template>
    <div class="login-wrap">
        
        <div class="login-background">

            


            <div class="login-logo-wrap">
                <img class="login-logo" :src="require('@/assets/login_logo.png')" alt="adendorffs_inventory_management_login_logo">
            </div>

            <div class="app-logo-wrap">
                <img class="app-logo" :src="require('@/assets/logo_black.png')" alt="logo_white">
            </div>

            <div class="login-input-wrapper">
                <div class="login-input-wrap">
                    <input type="text" class="login-username" v-model="user.username" placeholder="email">
                </div>
                <div class="login-input-wrap">
                    <input type="password" id="password" class="login-password" v-model="user.pw" placeholder="password">
                    <div class="psw-icon-wrap">
                        <font-awesome-icon v-if="!showPassword" @click="showPassword = true" class="show-hide-psw-icon" :icon="['fa', 'eye']" size="lg" />
                        <font-awesome-icon v-if="showPassword" @click="showPassword = false" class="show-hide-psw-icon" :icon="['fa', 'eye-slash']" size="lg" />
                    </div>
                </div>
                <div class="login-button" @click="loginUser()">
                    <span class="login-button-text">Login</span>
                </div>
            </div>


            <!-- <div class="polygon-box-wrapper">
                <div class="login-polygon-box"></div>
                
            </div> -->

            

        </div>

    </div>
</template>



<script>
export default {

    data() {
        return {
            showPassword: false,
            user: {
                username: '',
                pw: ''
            }
        }
    },




    watch: {
        showPassword: function () {
            var input = document.getElementById("password");
            if (this.showPassword)
                input.type = "text";
            else
                input.type = "password";
        },
    },





    methods: {

        loginUser: function() {
            this.$store.dispatch('Login/login', this.user)
        }

    }

}
</script>



<style>


.login-wrap {
    height: 100vh;
    margin-top: -60px;
}


.login-background {
    width: 100%;
    height: 100%;
    background-image: url('~@/assets/cows.jpg');
    background-size: cover;
    position: relative;
}










.login-polygon-box {
    width: 100%;
    height: 45%;
    background: var(--AppRed);
    clip-path: polygon(0 30%, 100% 0, 100% 100%, 0% 100%);
    -webkit-clip-path: polygon(0 30%, 100% 0, 100% 100%, 0% 100%);
    opacity: 0.6;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}



.app-logo-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.app-logo {
    width: 40%;
    max-width: 150px;
}







.login-input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 5em;
    width: 100%;
}

.login-input-wrapper input {
    text-align: center;
    background: rgba(255,255,255,0.85);
    color: var(--AppRed);
    /* margin: 3px; */
    border: none;
    border-radius: 3px;
    width: 100%;
    height: 40px;
    font-size: 16px;
}





/* 
    filter: blur(8px);
    -webkit-filter: blur(8px); 
*/



.login-input-wrap {
    /* border: 1px solid var(--App);
    border-radius: 3px; */
    margin-top: 15px;
    position: relative;
    min-width: 300px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.login-input-wrap:first-child {
    margin-top: 0;
}




.login-username {
    margin-top: 0;
}

.login-password {

}



.login-button {
    margin-top: 45px;
    color: var(--TextBlack);
    box-shadow: 4px 5px 10px 1px rgba(0,0,0,0.45);
    font-size: 20px;
    padding: 0;
    border: 2px solid var(--AppRed)/* rgb(250,250,250) */;
    font-weight: 700;
    cursor: pointer;
}

.login-button-text {
    width: max-content;
    height: 100%;
    margin: 5px;
    padding: 10px 28px;
    background: rgba(255,255,255,0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}




.login-logo-wrap {
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: rgba(240,240,240,0.40); */
}


.login-logo {

    width: 80%;
}




.psw-icon-wrap {
    height: 35px;
    position: absolute;
    top: 11px;
    right: 10px;
    z-index: 10;
}



.psw-icon-wrap .show-hide-psw-icon {
    color: rgb(30,30,30);
}








@media only screen and (min-width: 600px) {

    .login-logo {

        max-width: 450px;
    }


    .app-logo {
        /* max-width: 150px; */
        max-width: 150px;
        bottom: 10%;
    }



    .login-background {
        width: 100%;
        height: 100%;
        background-image: url('~@/assets/1600x1037_Cows.jpg');
        background-size: cover;
    }




    .login-input-wrap {
        display: flex;
        justify-content: center;
    }

    .login-input-wrapper {
        padding-top: 5em;
    }

    .login-input-wrapper input {
        max-width: 500px;
    }



}
    
</style>