// initial state
const state = () => ({
    title: '',
    titleIcon: []
})





// getters
const getters = {
    title: state => state.title,
    titleIcon: state => state.titleIcon
}





// actions
const actions = {
    resetTitle({ commit }) {
        commit('resetTitle');
    },


    setTitle({ commit }, { title, icon }) {
        commit('setTitle', { title, icon });
    },

}





// mutations
const mutations = {
    setTitle(state, { title, icon }) {
        state.title = title;
        state.titleIcon = icon;
        console.log('Title set: ', state)
    },




    resetTitle(state) {
        state.title = '';
        state.icon = [];
    }
}





export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}