<template>
    <div class="dashboard-wrap">
        
        <div class="tags-dashboard-heading-wrap">
            <!-- <h2><font-awesome-icon class="tags-icon" :icon="['fa', 'tags']"  size="lg" /> Tags Dashboard</h2> -->
            <div class="refresh-icon-wrap">
                <font-awesome-icon @click="refreshTags()" class="refresh-tags-icon icon" :class="{ 'disabled' : loadingCategories || loadingTags || updatingTags }" v-if="!loadingCategories && !loadingTags && !updatingTags" :icon="['fa', 'sync-alt']" size="lg" />
                <font-awesome-icon class="refresh-tags-icon icon" :class="{ 'disabled' : loadingCategories || loadingTags || updatingTags }" v-else :icon="['fa', 'sync-alt']" spin size="lg" />
            </div>
        </div>


        <div class="dashboard-heading-wrap">

            <font-awesome-icon @click="loadSelectCategory(false)" class="navigate-category-icon previous icon" :icon="['fa', 'circle-chevron-left']" size="lg" />
            <h3>{{ selectedCategory.category_name }}</h3>
            <font-awesome-icon @click="loadSelectCategory(true)" class="navigate-category-icon next icon" :icon="['fa', 'circle-chevron-right']" size="lg" />
        </div>


        
        <div class="tag-dashboard-scroll-section no-scroll-bar" v-if="selectedCategory && selectedCategory.tagsLoaded && selectedCategory.tags.length >= 1">
            <div id="TagCardsGrid" ref="TagCards" class="tag-card-grid" :class="{ 'swipe-left' : swipe.direction == 'left' && swipe.swiping, 'swipe-right' : swipe.direction == 'right' && swipe.swiping }">
                <div class="tag-card-grid-item" v-for="(tag, index) in selectedCategory.tags" :key="index" >
                    <div class="tag-card-wrap">

                        <div class="tag-card-heading-wrap">
                            <h3>{{ tag.tag_id_i_description }}</h3>
                            <p>{{ tag.tag_id_i }}</p>
                        </div>

                        <div class="tag-content-wrap">
                            <p>Last Update: </p>
                            <p class="bold">2024-08-31 10:31</p>

                            <p>Channel: </p>
                            <p class="bold">3</p>

                            <p>Attendance: </p>
                            <p class="bold">Yes / No</p>

                            <p>RSSI: </p>
                            <p class="bold">-12</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="!loadingCategories || loadingTags" class="dashboard-message-wrap">
            <p>No Tags in {{ selectedCategory.category_name }}</p>
        </div>

    </div>
</template>




<script>
import { mapGetters } from 'vuex';

export default {

    data() {
        return {
            compSelectedCategoryId: 0,

            previousCategory: '',
            selectedCategory: '',
            nextCategory: '',

            swipe: {
                swiping: false,
                startX: '',
                endX: '',
                direction: '',
                previousRead: '',
                currentRead: '',
                compoundMovement: 0
            },

            tagCardsElm: ''
        }
    },



    computed: {
        ...mapGetters({
            user: ['User/user'],
            loadingCategories: ['Categories/loadingCategories'],
            loadingTags: ['Categories/loadingTags'],
            updatingTags: ['Categories/updatingTags'],
            userCategories: ['Categories/userCategories'],
            selectedCategoryId: ['Categories/dashboardCatId'],
        })
    },




    watch: {
        selectedCategoryId: function() {
            if(this.userCategories && this.selectedCategoryId)
                this.selectedCategory = this.userCategories.find(cat => cat.id == this.selectedCategoryId);
        },


        selectedCategory: function() {
            if(this.selectedCategory)
            {
                var selectedIndex = this.userCategories.findIndex(cat => cat.id == this.selectedCategoryId);

                // End
                if(selectedIndex == this.userCategories.length - 1)
                {
                    this.previousCategory = this.userCategories[selectedIndex - 1];
                    this.nextCategory = this.userCategories[0];
                    // console.log('End')
                }
                // Start
                else if(selectedIndex == 0)
                {
                    this.previousCategory = this.userCategories[this.userCategories.length - 1];
                    this.nextCategory = this.userCategories[selectedIndex + 1];
                    // console.log('Start')
                }
                // Elsewhere
                else
                {
                    // console.log('Elsewhere')
                    this.previousCategory = this.userCategories[selectedIndex - 1];
                    this.nextCategory = this.userCategories[selectedIndex + 1];
                }

                // console.log('Prev Cat: ', this.previousCategory);
                // console.log('Curr Cat: ', this.selectedCategory);
                // console.log('Next Cat: ', this.nextCategory);
            }
        }
    },




    mounted() {
        this.checkUserCategories();

        window.addEventListener('touchstart', this.startSwipe);
        window.addEventListener('touchend', this.endSwipe);
    },


    beforeDestroy() {
        window.removeEventListener('touchstart', this.startSwipe);
        window.removeEventListener('touchend', this.endSwipe);
    },




    methods: {


        startSwipe: function(e) {
            // e.preventDefault();
            
            this.swipe.swiping = true;
            this.swipe.startX = e.touches[0].clientX;
            this.tagCardsElm = document.getElementById('TagCardsGrid');
            if(this.tagCardsElm)
            {
                this.tagCardsElm.style.position = 'absolute';
                this.tagCardsElm.style.left = 0;
            }
            window.addEventListener('touchmove', this.touchMove);
        },


        touchMove: function(e) {
            // e.preventDefault();
            // console.log('Moving on touch: ', e);
            this.swipe.currentRead = e.changedTouches[0].clientX;
            !this.swipe.previousRead ? this.swipe.previousRead = e.changedTouches[0].clientX : null;
            var movement = this.swipe.currentRead - this.swipe.previousRead;
            this.swipe.compoundMovement += movement;
            this.swipe.previousRead = this.swipe.currentRead;

            // console.log('Start X: ', this.swipe.startX, this.swipe.startX + 30);
            // console.log('total movement: ', this.swipe.compoundMovement);
            if(this.swipe.compoundMovement < 30 && this.swipe.compoundMovement > -30) { return }

            // console.log(Number(movement.toFixed(2)), Number(this.swipe.compoundMovement.toFixed(2))/* ' left: ', this.tagCardsElm.style.left, 'clientX: ', this.tagCardsElm.clientX */);
            this.tagCardsElm ? this.tagCardsElm.style.left = this.tagCardsElm.offsetLeft + movement + 'px' : null;
            // console.log(this.tagCardsElm.style.left);


            
            
        },



        endSwipe: function(e) {
            // e.preventDefault();
            // console.log('Swipe ended: ', e);
            this.swipe.swiping = false;
            this.swipe.endX = e.changedTouches[0].clientX;
            this.swipe.compoundMovement = 0;
            this.tagCardsElm ? this.tagCardsElm.style.left = 0 : null;
            window.removeEventListener('touchmove', this.touchMove);

            // console.log('Swipe: ', this.swipe);

            if(this.swipe.startX > this.swipe.endX)
            {
                if(this.swipe.startX > this.swipe.endX + 80)
                    this.loadSelectCategory(true);
            }
            else   
            {
                if(this.swipe.startX + 80 < this.swipe.endX)
                    this.loadSelectCategory(false);
            }

            this.swipe.startX = '';
            this.swipe.endX = '';
            this.swipe.previousRead = '';
            this.swipe.currentRead = '';
        },











        loadSelectCategory: function(isNext) {

            var selectedIndex = this.userCategories.findIndex(cat => cat.id == this.selectedCategoryId); 
            var beforePreviousCategory = '';
            var afterNextCategory = '';

            // End
            if(selectedIndex == this.userCategories.length - 1)
            {
                this.previousCategory = this.userCategories[selectedIndex - 1]; 
                this.nextCategory = this.userCategories[0];
                beforePreviousCategory = this.userCategories[selectedIndex - 2]; 
                afterNextCategory = this.userCategories[1];
            }
            // Start
            else if(selectedIndex == 0)
            {
                this.previousCategory = this.userCategories[this.userCategories.length - 1];
                this.nextCategory = this.userCategories[selectedIndex + 1];
                beforePreviousCategory = this.userCategories[this.userCategories.length - 2]; 
                afterNextCategory = this.userCategories[selectedIndex + 2];
            }
            // Elsewhere
            else
            {
                // console.log('Elsewhere')
                this.previousCategory = this.userCategories[selectedIndex - 1];
                this.nextCategory = this.userCategories[selectedIndex + 1];
                beforePreviousCategory = selectedIndex == 1 ? this.userCategories[this.userCategories.length - 1] : this.userCategories[selectedIndex - 2]; 
                afterNextCategory = selectedIndex == this.userCategories.length - 2 ? this.userCategories[0] : this.userCategories[selectedIndex + 2];
            }

            
            // console.log('Before previous: ', beforePreviousCategory);
            // console.log('After next: ', afterNextCategory);
            

            if(isNext) 
            {
                this.compSelectedCategoryId = this.nextCategory.id
                this.$store.dispatch('Categories/dashboardCatId', this.nextCategory.id);
                this.$store.dispatch('Categories/loadCategoryTags', { userId: this.user.id, categoryId: this.nextCategory.id, refresh: false });
                this.selectedCategory = this.nextCategory;
                this.$store.dispatch('Categories/loadCategoryTags', { userId: this.user.id, categoryId: afterNextCategory.id, refresh: false });
            }
            else
            {
                this.compSelectedCategoryId = this.previousCategory.id
                this.$store.dispatch('Categories/dashboardCatId', this.previousCategory.id);
                this.$store.dispatch('Categories/loadCategoryTags', { userId: this.user.id, categoryId: this.previousCategory.id, refresh: false });
                this.selectedCategory = this.previousCategory;
                this.$store.dispatch('Categories/loadCategoryTags', { userId: this.user.id, categoryId: beforePreviousCategory.id, refresh: false });
            }

        },






        refreshTags: function() {
            this.$store.dispatch('Categories/loadCategoryTags', { userId: this.user.id, categoryId: this.selectedCategoryId, refresh: true });
        },









        setSelectedCategory: function() {

            if(this.selectedCategoryId && this.selectedCategory && this.selectedCategory.tagsLoaded) { return }

            this.selectedCategory = '';

            if(this.selectedCategoryId !== '')
            {
                this.compSelectedCategoryId = this.selectedCategoryId; 
                this.selectedCategory = this.userCategories.find(cat => cat.id == this.selectedCategoryId);
            }

            if(this.compSelectedCategoryId == 0 && this.selectedCategoryId === '')
                this.$store.dispatch('Categories/dashboardCatId', 0);

            if(this.selectedCategory && !this.selectedCategory.tagsLoaded)
            {
                // console.log('Loading category tags: ', this.selectedCategoryId);
                this.$store.dispatch('Categories/loadCategoryTags', { userId: this.user.id, categoryId: this.selectedCategoryId, refresh: false });
            }
            },




        checkUserCategories: async function() {
            if(!this.userCategories || this.userCategories && this.userCategories.length <= 0)   
            {
                await this.$store.dispatch('Categories/getUserCategories', this.user.id);
                this.setSelectedCategory();
            }
            else
                this.setSelectedCategory();
        },
    }
    
}
</script>



<style>


.dashboard-wrap {
    /* padding-top: 70px; */
}


.dashboard-heading-wrap {
    height: 70px;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: center;
    justify-content: center;
    text-align: center;
}


.navigate-category-icon {
    font-size: 24px;
    color: var(--AppRed);
}

.navigate-category-icon.previous {
    margin-right: 10px;
    justify-self: flex-end;
}

.navigate-category-icon.next {
    margin-left: 10px;
    justify-self: flex-start;
}






.tags-dashboard-heading-wrap {
    position: fixed;
    top: 75px;
    right: 10px;

    display: flex;
    justify-content: space-between;
    align-items: center;
}


.tags-dashboard-heading-wrap .tags-icon {
    color: var(--AppRed);
    font-size: 20px;
    margin-right: 8px;
}

.tags-dashboard-heading-wrap h2 {
    display: flex;
    align-items: center;
}

.refresh-icon-wrap {
    background: var(--AppWhite);
    border-radius: 3px;
    padding: 10px;
}

.refresh-tags-icon {
    color: var(--OkGreen); 
}











.tag-dashboard-scroll-section {
    overflow-y: scroll;
    height: calc(100vh - 130px);
    width: calc(100vw + 10px);
    margin: 0 -10px;
    box-shadow: inset 0 -8px 16px 0 rgb(0 0 0 / 20%);
    padding-bottom: 100px;
    position: relative;
}



.tag-card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(80vw, 80vw));
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0px;
    width: 100%;
}


/* @keyframes swipe-cards-left {
    
}


.tag-card-grid.swipe-left {
    left: -200px;
    transition: left 500ms ease-in;
}

@keyframes swipe-cards-right {

}


.tag-card-grid.swipe-right {
    right: -200px;
    transition: right 500ms ease-in;
} */







.tag-card-wrap {
    width: 80vw;
    height: 180px;
    border: none;
    border-radius: 3px;
    background: var(--BackgroundWhite);
    box-shadow: 3px 4px 12px 0 rgba(0,0,0,0.18);
    margin-bottom: 35px; 
}

.tag-card-wrap:first-child {
    margin-top: 25px;
}




.tag-card-heading-wrap {
    display: grid;
    grid-template-columns: 4fr 1.5fr;
    align-items: center;
    border-bottom: 1px solid var(--AppBlack);
}


.tag-card-heading-wrap h3 {
    padding-left: 10px;
    font-size: 18px;
}

.tag-card-heading-wrap p {
    /* margin: -5px 0; */
    justify-self: center;
    text-align: center;
    /* padding: 5px 0; */
    border-left: 1px solid var(--AppRed);
    width: 100%;
    padding: 7px 0;
    font-size: 18px;
}







.tag-content-wrap {
    display: grid;
    grid-template-columns: 1fr 2fr;

    padding-top: 10px;
    padding-left: 10px;
    font-size: 14px;
}

















.dashboard-message-wrap {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
    
</style>