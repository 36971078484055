<template>
    <div class="category-dashboard-wrap">

        <div class="categories-heading-wrap">
            <!-- <h2><font-awesome-icon class="categories-icon" :icon="['fa', 'list']"  size="lg" /> Categories</h2> -->
            <div class="refresh-icon-wrap">
                <font-awesome-icon @click="getUserCategories()" class="refresh-categories-icon icon" :class="{ 'disabled' : editMode || loadingAddCategory || loadingCategories }" v-if="!loadingCategories" :icon="['fa', 'sync-alt']" size="lg" />
                <font-awesome-icon class="refresh-categories-icon icon" :class="{ 'disabled' : editMode || loadingAddCategory || loadingCategories }" v-else :icon="['fa', 'sync-alt']" spin size="lg" />
            </div>
        </div>
        


        <!-- <template v-if="!editMode"> -->
            <div class="add-category-wrap">
                <input type="text" placeholder="Add Category" v-model="addCategoryName" :disabled="loadingAddCategory || loadingCategories || editMode">
                <button @click="addCategory()" :disabled="loadingAddCategory || loadingCategories || editMode"><font-awesome-icon class="add-category-icon btn-icon" :class="{ 'disabled' : loadingAddCategory || loadingCategories }" :icon="['fa', 'circle-plus']" size="lg" /></button>
            </div>
        <!-- </template> -->
        <!-- <template v-else>
            <div class="edit-mode-buttons-wrap" v-if="editMode && !loadingCategories">
                <button class="save-category-changes-btn" @click="saveEdit()"><font-awesome-icon class="btn-icon" :icon="['fa', 'floppy-disk']" size="lg" /> Save Changes</button>
                <button class="cancel-category-changes-btn" @click="cancelEdit()"><font-awesome-icon class="btn-icon" :icon="['fa', 'ban']" size="lg" /> Cancel</button>
            </div>
        </template> -->
        



        <div class="data-grid category-grid headings">
            <h4>Category Name</h4>
            <h4 class="centered">Edit</h4>
            <h4 class="centered">Delete</h4>
            <h4 class="centered">Tags</h4>
        </div>       

        <!-- <template v-if="!loadingCategories && !loadingAddCategory && !updatingCategories"> -->

            <div class="custom-scroller categories-scroll-section" :class="{ 'loading' : loadingCategories || loadingAddCategory || updatingCategories }">
                <div class="scroll-section-loader-wrap" v-if="loadingCategories || loadingAddCategory || updatingCategories">
                    <font-awesome-icon class="loading-spinner categories" :icon="['fa', 'spinner']" spin size="lg" />
                    <p>{{ loadingAddCategory ? 'Adding Category...' : updatingCategories ? 'Updating Categories...' : 'Loading Categories...' }}</p>
                </div>
                <div class="data-grid category-grid data" v-for="cat in userCategories" :key="cat.id">
                    <!-- <template v-if="cat.id > 0"> -->
                        <div class="data-grid-item-wrap" v-if="!cat.editing">
                            <p class="data-grid-item cat-name"  :class="{ 'deleted' : cat.deleted }" >{{ cat.category_name }}</p>
                        </div>
                        <!-- <div class="data-grid-item-wrap" v-else> -->
                            <input :id="'CatEdit' + cat.id" type="text" v-else class="data-grid-item" :class="{ 'deleted' : cat.deleted }" v-model="cat.editName">
                        <!-- </div> -->
                        <div class="data-grid-item-wrap center" @click="toggleEditing(cat)" v-if="!cat.id <= 0">
                            <font-awesome-icon class="edit-category-icon icon" v-if="!cat.deleted" :class="{ 'editing' : cat.editing, 'disabled' : cat.id == 0 }" :icon="['fa', 'pencil']" size="lg" />
                            <span v-else class="material-symbols-outlined edit-category-icon-off">edit_off</span>
                        </div>
                        <div class="data-grid-item-wrap center" v-else></div>
                        <div class="data-grid-item-wrap center" @click="toggleDeleted(cat)" v-if="!cat.id <= 0">
                            <font-awesome-icon class="delete-category-icon icon" :class="{ 'deleted' : cat.deleted, 'disabled' : cat.id == 0 }" :icon="['fa', 'trash']" size="lg" />
                        </div>
                        <div class="data-grid-item-wrap center" v-else></div>
                        <div class="data-grid-item-wrap center" @click="viewCategoryTags(cat)">
                            <font-awesome-icon class="view-category-tags-icon" :icon="['fa', 'tags']" size="lg" />
                        </div>
                    <!-- </template> -->
                </div>
            </div>

        <!-- </template> -->
        <!-- <template v-else>
            <div class="loading-categories-wrap">
                <font-awesome-icon class="loading-spinner categories" :icon="['fa', 'spinner']" spin size="lg" />
                <p>{{ loadingAddCategory ? 'Adding Category...' : updatingCategories ? 'Updating Categories...' : 'Loading Categories...' }}</p>
            </div>
        </template> -->


        <div class="edit-mode-buttons-wrap" v-if="editMode && !loadingCategories && !loadingAddCategory && userCategories">
            <button class="save-category-changes-btn" @click="saveEdit()"><font-awesome-icon class="btn-icon" :icon="['fa', 'floppy-disk']" size="lg" /> Save Changes</button>
            <button class="cancel-category-changes-btn" @click="cancelEdit()"><font-awesome-icon class="btn-icon" :icon="['fa', 'ban']" size="lg" /> Cancel</button>
        </div>

        
    </div>
</template>



<script>
import { mapGetters } from 'vuex';
import { axios } from '../../axios/axios';

export default {

    data() {
        return {
            /* userCategories: [], */
            editMode: false,
            addCategoryName: '',

            /* loadingCategories: false, */
            updatingCategories: false,
            loadingAddCategory: false
        }
    },



    computed: {
        ...mapGetters({
            user: ['User/user'],
            loadingCategories :['Categories/loadingCategories'],
            userCategories: ['Categories/userCategories']
        })
    },



    mounted() {

        this.checkUserCategories();

    },




    methods: {


        viewCategoryTags: function(cat) {
            this.$store.dispatch('Categories/selectedCategoryId', cat.id);
            this.$store.dispatch('Categories/loadCategoryTags', { userId: this.user.id, refresh: false });
            this.$router.push('/tag_categories');
        },


        addCategory: function() {

            var toast = {
                shown: false,
                type: 'warning', // ['info', 'warning', 'error', 'okay']
                heading: 'Please enter a category name', // (Optional)
                body: '', 
                time: 4000, // in milliseconds
                icon: '' // leave blank for default toast type icon
            }

            if(!this.addCategoryName) 
            {
                this.$store.dispatch('Toast/toast', toast);
                return 
            }



            
            
            this.addCategoryName = this.addCategoryName.trim();
            var categoryExists = this.userCategories.find(cat => cat.category_name.trim() == this.addCategoryName);

            if(categoryExists)
            {
                toast.type = 'warning';
                toast.heading = 'Category already exists';
                toast.body = 'Please choose a unique name for your new category';
                toast.time = 6000;
                this.$store.dispatch('Toast/toast', toast);
                this.loadingAddCategory = false;
                return;
            }





            this.loadingAddCategory = true;
            var newCatId = '';

            axios.post('categories/create_user_category.php', { userId: this.user.id, catName: this.addCategoryName })
			.then(resp => {
                console.log(resp.data);
				if(resp.data.status)
                {
                    toast.type = 'okay';
                    toast.heading = 'Category added';
                    toast.body = '';
                    toast.time = 2500;

                    newCatId = resp.data.data.categoryId;

                    this.userCategories.push(
                        {
                            id: newCatId,
                            category_name: this.addCategoryName,
                            user_id: this.user.id,
                            editName: this.addCategoryName,
                            deleted: false,
                            editing: false
                        }
                    );

                    this.$store.dispatch('Categories/setUserCategories', this.userCategories);
                    this.addCategoryName = '';
                    this.$store.dispatch('Toast/toast', toast);
                }
                else
                {
                    toast.type = 'error';
                    toast.heading = 'Server Error';
                    toast.body = resp.data.message;
                    toast.time = 4000;
                    this.$store.dispatch('Toast/toast', toast);
                }

                
                
			})
            .catch(err => {
                console.error('Connection Error: ', err);
                console.error('Connection Error Response: ', err.response);
                var toast = {
                    shown: false,
                    type: 'error', // ['info', 'warning', 'error', 'okay']
                    heading: 'Server Error', // (Optional)
                    body: 'Please try again later', 
                    time: 500, // in milliseconds
                    icon: '' // leave blank for default toast type icon
                }
                this.$store.dispatch('Toast/toast', toast);
            })

            this.loadingAddCategory = false;
            
        },



        saveEdit: async function() {

            this.updatingCategories = true;
            this.editMode = false;
            var allOkay = true;
            var categoryHasTags = false;

            await Promise.all(this.userCategories.map(async cat => {

                if(cat.deleted)
                {

                    await this.$store.dispatch('Categories/loadCategoryTags', {userId: this.user.id, refresh: false, categoryId: cat.id});
                    
                    if(cat.tagsLoaded && cat.tags.length >= 1)
                    {
                        cat.deleted = false;
                        allOkay = false;
                        categoryHasTags = true;

                        var toast = {
                            shown: false,
                            type: 'warning', // ['info', 'warning', 'error', 'okay']
                            heading: cat.category_name + ' has tags assigned.', // (Optional)
                            body: 'Please move the tags to another category first.',
                            time: 8000, // in milliseconds
                            icon: '' // leave blank for default toast type icon
                        }

                        this.$store.dispatch('Toast/toast', toast);
                    }

                    if(cat.deleted)
                    {
                        await axios.post('categories/delete_user_category.php', 
                            { 
                                userId: this.user.id, 
                                catId: cat.id
                            }
                        )
                        .then(resp => {
                            if(!resp.data.status)
                                allOkay = false;
                        })
                        .catch(err => {
                            console.error('Connection Error: ', err);
                            console.error('Connection Error Response: ', err.response);
                            var toast = {
                                shown: false,
                                type: 'error', // ['info', 'warning', 'error', 'okay']
                                heading: 'Server Error', // (Optional)
                                body: 'Please try again later', 
                                time: 500, // in milliseconds
                                icon: '' // leave blank for default toast type icon
                            }
                            this.$store.dispatch('Toast/toast', toast);
                        })
                        return
                    }
                }

                if(cat.category_name != cat.editName)
                {
                    cat.category_name = cat.editName;
                    await axios.post('categories/update_user_category.php', 
                        { 
                            userId: this.user.id, 
                            catId: cat.id, 
                            catName: cat.category_name
                        }
                    )
                    .then(resp => {
                        if(!resp.data.status)
                            allOkay = false;
                    })
                    .catch(err => {
                        console.error('Connection Error: ', err);
                        console.error('Connection Error Response: ', err.response);
                        var toast = {
                            shown: false,
                            type: 'error', // ['info', 'warning', 'error', 'okay']
                            heading: 'Server Error', // (Optional)
                            body: 'Please try again later', 
                            time: 500, // in milliseconds
                            icon: '' // leave blank for default toast type icon
                        }
                        this.$store.dispatch('Toast/toast', toast);
                    })
                }

                cat.editing = false;
            }))
            .then(() => {

                var toast = {
                    shown: false,
                    type: 'okay', // ['info', 'warning', 'error', 'okay']
                    heading: 'Categories updated successfully', // (Optional)
                    body: '',
                    time: 2500, // in milliseconds
                    icon: '' // leave blank for default toast type icon
                }

                if(allOkay && !categoryHasTags)
                {
                    this.$store.dispatch('Toast/toast', toast);
                }
                else if(!allOkay && !categoryHasTags)
                {
                    toast.type = 'error';
                    toast.heading = 'Server Error';
                    toast.body = 'Could not update categories';
                    toast.time = 4000;

                    this.$store.dispatch('Toast/toast', toast);
                }

            })

            this.$store.dispatch('Categories/updateUserCategories');
            this.editMode = false;
            this.updatingCategories = false;

        },



        cancelEdit: function() {
            this.userCategories.map(cat => {
                cat.editName = cat.category_name;
                cat.editing = false;
                cat.deleted = false;
            });
            this.editMode = false;
            
        },


        toggleEditing: function(cat) {
            if(cat.deleted || cat.id == 0) { return }
            cat.editing = !cat.editing;
            if(cat.editing)
            {
                
                this.editMode = true;

                setTimeout(() => {
                    var input = document.getElementById('CatEdit'+cat.id);
                    console.log("input: ", input);
                    if(input) 
                    {
                        input.focus();
                        input.select();
                    }
                
                }, 50); 
                
            }
            else
                this.editMode = this.userCategories.find(cat => cat.editing || cat.deleted) ? true : false; 
        },


        toggleDeleted: function(cat) {

            if(cat.id == 0) { return }

            cat.deleted = !cat.deleted;
            
            if(cat.deleted)
            {
                this.editMode = true;
                cat.editing = false;
            }
            else
                this.editMode = this.userCategories.find(cat => cat.deleted || cat.editing) ? true : false; 
                
            
        },






        checkUserCategories: function() {
            if(!this.userCategories || this.userCategories && this.userCategories.length <= 0)
                this.$store.dispatch('Categories/getUserCategories', this.user.id);
        },





        getUserCategories: function() {
            this.$store.dispatch('Categories/getUserCategories', this.user.id);  
        }
    }
    
}
</script>



<style>


.category-dashboard-wrap {
    padding: 0 10px;
    padding-top: 15px;
}


.categories-heading-wrap {

    position: fixed;
    top: 75px;
    right: 10px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 20px; */
}


.categories-icon {
    color: var(--AppRed);
    font-size: 20px;
    margin-right: 8px;
}

.categories-heading-wrap h2 {
    display: flex;
    align-items: center;
}


.refresh-icon-wrap {
    background: var(--AppWhite);
    border-radius: 3px;
    padding: 10px;
}

.refresh-categories-icon {
    color: var(--OkGreen);
    
}






.add-category-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 15px;
    height: 40px;
}

.add-category-wrap input {
    margin-right: 7px;
}

.add-category-wrap button {
    margin-left: 7px;
}


.add-category-icon {
    color: var(--OkGreen);
}

.btn-icon.add-category-icon {
    margin: 0;
}





.categories-scroll-section {
    overflow-y: scroll;
    height: calc(100vh - 165px);
    width: 100vw;
    margin: 0 -10px;
    box-shadow: inset 0 -8px 16px 0 rgb(0 0 0 / 20%);
    padding-bottom: 100px;
}

.categories-scroll-section.loading {
    overflow-y: hidden;
}


.scroll-section-loader-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(240,240,240,0.85);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 100;
}


    

.category-grid {
    grid-template-columns: 3fr 1fr 1fr 1fr;
    align-content: center;
    width: 100%;
    padding: 0 10px;
}


.category-grid.headings {
    background: var(--BackgroundWhite);
    color: var(--TextGrey);
    margin: 0 -10px;
    padding: 0 10px;
    padding-right: 17px;
    border-bottom: 1px solid var(--AppRed);
    width: 100vw;
    height: 35px;

}


.category-grid.headings h4 {
    padding-left: 10px;
}


.category-grid.headings h4.centered {
    justify-self: center;
    text-align: center;
    padding-left: 0;
}

.category-grid.data p {
    min-height: 40px;
    height: 100%;
    padding-left: 10px;
}



.category-grid.data input {
    border: none;
    border-bottom: 1px solid rgba(40,40,40,0.4);
    height: 40px;
}

.category-grid.data input:focus {
    border-bottom: 2px solid var(--AppRed);    
}



.category-grid.data p.deleted,
.category-grid.data input.deleted {
    text-decoration: line-through;
}


.data-grid-item.cat-name {
    display: flex;
    align-items: flex-end;
}




.edit-category-icon,
.delete-category-icon,
.view-category-tags-icon {
    font-size: 18px;
    color: var(--OkGreen);
    justify-self: center;
    align-self: flex-end;
    cursor: pointer;
    padding-bottom: 5px;
}


.edit-category-icon.editing {
    color: var(--LightGreen);
}


.edit-category-icon-off {
    cursor: pointer;
    color: rgb(150,150,150);
}


.delete-category-icon {
    cursor: pointer;
    color: rgb(80,80,80);
}

.delete-category-icon.deleted {
    color: var(--WarningRed);
}


.view-category-tags-icon {
    color: var(--AppRed);
}



.edit-mode-buttons-wrap {
    position: fixed;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;

    background: rgba(220,220,220,0.85);
    /* border: 3px solid rgb(250,250,250); */
    border-radius: 3px;

    width: max-content;
    padding: 12px 20px;

    display: flex;
    align-items: center;
    justify-content: center;
}



.save-category-changes-btn {
    background: var(--OkGreen);
    color: var(--TextWhite);
    margin-right: 10px;
}


.cancel-category-changes-btn {
    background: var(--WarningRed);
    color: var(--TextWhite);
    margin-left: 10px;
}








.loading-categories-wrap,
.adding-category-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 20px;
    padding: 18px 10px;
}


.adding-category-wrap {
    flex-direction: row;
    align-items: center;
    margin-top: 0;
    margin-bottom: 30px;
    padding: 0;
}


.loading-spinner.categories {
    margin-bottom: 8px;
    color: var(--AppRed);
    font-size: 24px;
}

.loading-spinner.categories.add {
    margin-bottom: 0;
    margin-right: 8px;
}

</style>