// initial state
const state = () => ({
    pageLoader: {
        loading: true,
        heading: '',
        message: '',
    }
    
})





// getters
const getters = {
    loading: state => state.pageLoader.loading,
    heading: state => state.pageLoader.heading,
    message: state => state.pageLoader.message
}





// actions
const actions = {
    pageLoader({ commit }, settings) {
        commit('pageLoader', settings);
    },

}





// mutations
const mutations = {
    pageLoader(state, settings) {
        state.pageLoader.loading = settings.loading;
        state.pageLoader.heading = settings.heading;
        state.pageLoader.message = settings.message;
    },
}





export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}